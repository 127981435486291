import {combineReducers} from "redux";
import {userGamblingResult} from "@atg-responsible-gambling-shared/user-gambling-result-domain";
import {userGamblingSummary} from "@atg-responsible-gambling-shared/user-gambling-summary-domain";
import personalization from "@atg-global-shared/personalization/domain/personalizationReducer";
import {memberReducer} from "@atg-global-shared/member-data-access";
import cms from "@atg-global-shared/legacy-cms/domain/cmsReducer";
import changePassword from "@atg-global-shared/change-password-feature/domain/changePasswordReducer";
import {limitsReducer as limits} from "@atg-responsible-gambling-shared/limits-domain";
import {kycQuestionnaire} from "@atg-aml-shared/kyc-domain";
import {userSettingsReducer} from "@atg-global-shared/user-settings";
import {createHorseReducer} from "@atg-horse/horse-app";
// Remove this when payment microFE is out and stable
import {paymentReducer} from "@atg-payment-shared/money-page-domain";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";
import {modals, modalData} from "atg-modals/modalReducer";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<any, any>({
        // reducers that are lazily injected later will end up here
        ...asyncReducers.root,
        activeBreakpoint, // TODO: deprecated, should be removed after refactoring to `atg-breakpoints`
        auth: () => window._frameStore.getState().auth,
        accessToken: () => window._frameStore.getState().accessToken,
        changePassword,
        cms,
        limits,
        login: () => window._frameStore.getState().login,
        loginHAAPI: () => window._frameStore.getState().loginHAAPI,
        mainMenu: () => window._frameStore.getState().mainMenu,
        mainNavbar: () => window._frameStore.getState().mainNavbar,
        member: memberReducer,
        modals,
        modalData,
        personalization,
        userGamblingResult,
        userGamblingSummary,
        router: () => window._frameStore.getState().router,
        // This will be removed when mina-pengar page will live inside Payment microFE
        kycQuestionnaire,
        user: () => window._frameStore.getState().user,
        userSettings: userSettingsReducer,
        verticals: () => window._frameStore.getState().verticals,
        horse: createHorseReducer(asyncReducers.horse),
        payment: paymentReducer,
    });

export default createRootReducer;
