import {fromPromise} from "@apollo/client";
import uuidv4 from "uuid/v4";
import {setContext} from "@apollo/client/link/context";
import type {Dispatch} from "redux";
import {onError} from "@apollo/client/link/error";
import {flatMap, flow, includes, map, some} from "lodash/fp";
import type {GraphQLError} from "graphql";
import {AccessTokenConstants, AuthActions} from "@atg-shared/auth";
import * as Storage from "@atg-shared/storage";
import {SkippedRetryOnManualLogin, AccessTokenMissingError} from "./apolloUtils";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

export interface AccessTokenResult {
    token: string;
    hadToLogin: boolean;
}

export const authLink = setContext(async (_, {headers, auth}) => {
    if (auth) {
        const accessToken = await Storage.getItem(
            AccessTokenConstants.ACCESS_TOKEN_STORAGE_KEY,
        );

        if (!accessToken) throw new AccessTokenMissingError("No access token present.");

        return {
            headers: {
                ...headers,
                authorization: `Bearer ${accessToken}`,
            },
        };
    }

    return {headers};
});

/*
Explanation of what are business headers: https://confluence-atg.riada.cloud/pages/viewpage.action?spaceKey=AR&title=Guideline+for+Business+Operation+Identities
TL;DR: backend needs them to track the bet accross all their microservices
 */
export const businessHeadersLink = setContext((_, {headers, businessIds}) => {
    if (businessIds) {
        return {
            headers: {
                ...headers,
                "request-id": uuidv4(),
                "business-operation-id": headers?.businessOperationId
                    ? headers.businessOperationId
                    : uuidv4(),
            },
        };
    }

    return {headers};
});

/**
 * Link that handles errors
 */
export const errorLink = (dispatch: Dispatch) =>
    onError(({graphQLErrors, operation, forward}) => {
        if (graphQLErrors) {
            const hasAuthenticationError = flow(
                flatMap((errors: GraphQLError) => errors.extensions?.applicationErrors),
                map((error) => error?.code),
                some((code) => includes(code, [UNAUTHORIZED, FORBIDDEN])),
            );

            if (hasAuthenticationError(graphQLErrors as GraphQLError[])) {
                // authenticate - "hook" into saga by dispatching an bffAuthentication action
                const promise = new Promise<AccessTokenResult>((resolve, reject) => {
                    // send in promise's resolve and reject as a callback to the bffAuthentication
                    dispatch(AuthActions.bffAuthentication(resolve, reject));
                });

                // use apollo method that converts a promise to observable
                return fromPromise(promise).flatMap((result: AccessTokenResult) => {
                    // retry the request if skipRetryOnManualLogin was not set or did not have to login during authentication
                    if (
                        !operation.getContext().skipRetryOnManualLogin ||
                        !result.hadToLogin
                    ) {
                        const oldHeaders = operation.getContext().headers;
                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                authorization: `Bearer ${result.token}`,
                            },
                        });

                        return forward(operation);
                    }

                    throw new SkippedRetryOnManualLogin("Skipped retry on manual login");
                });
            }
        }
        return undefined;
    });
