import type {LazyStore} from "@atg-shared/lazy-store";
import {runLazySaga} from "@atg-shared/lazy-store";
// @ts-expect-error Flow
import cmsSaga from "@atg-global-shared/legacy-cms/domain/cmsSaga";
import changePassword from "@atg-global-shared/change-password-feature/domain/changePasswordSaga";
import {highlightsSaga, highlightsPushListenerSaga} from "@atg-horse-shared/highlights";
import {userSettingsSaga} from "@atg-global-shared/user-settings";
import {hotGamesPushListenerSaga} from "@atg-horse-shared/hotgames";
import {fetchSaga} from "@atg-shared/fetch-redux";
// When payment microFE is in place, this will be removed from Global
import {kycQuestionnairePaymentSaga} from "@atg-aml-shared/kyc-domain";
import {
    DepositSaga,
    DepositFlowSaga,
    DepositAnalyticsSaga,
} from "@atg-payment-shared/deposit-domain";
import cleanupApolloCacheOnLogoutSaga from "./domain/cleanupApolloCacheOnLogoutSaga";

export default function rootSaga(store: LazyStore<any, any>) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, cmsSaga);
    runLazySaga(store, changePassword);
    runLazySaga(store, userSettingsSaga);
    runLazySaga(store, highlightsSaga);
    runLazySaga(store, highlightsPushListenerSaga, store.dispatch);
    runLazySaga(store, hotGamesPushListenerSaga, store.dispatch);
    runLazySaga(store, cleanupApolloCacheOnLogoutSaga);
    runLazySaga(store, kycQuestionnairePaymentSaga);
    runLazySaga(store, DepositSaga);
    runLazySaga(store, DepositFlowSaga);
    runLazySaga(store, DepositAnalyticsSaga);
}
