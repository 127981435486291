import {PAYMENT_URL, PAYMENT_URL_NEW, USER_SERVICE_URL} from "@atg-shared/service-url";
import Features, {depositV2CreditCard} from "@atg-shared/client-features";
// Fix when atg-ui-messages is separated
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {AmountOptions} from "@atg-payment-shared/deposit-types";

export const MIN_DEPOSIT_AMOUNT = 5_000; // 50 SEK
export const MAX_DEPOSIT_AMOUNT = 900_000_000; // 9 000 000 SEK

export const amountOptions: AmountOptions = {
    amounts: [20_000, 50_000, 100_000, 500_000],
    selected: null,
};

export const amountOptionsSmall: AmountOptions = {
    amounts: [MIN_DEPOSIT_AMOUNT, 15_000, 30_000, 50_000],
    selected: null,
};

export const DepositURLs = {
    DEPOSIT_URL: `${USER_SERVICE_URL}/payin`,
    CREDIT_CARD_URL: `${
        Features.isEnabled(depositV2CreditCard) ? PAYMENT_URL_NEW : PAYMENT_URL
    }/deposit/creditcard`,
    OPTIONS_URL: `${PAYMENT_URL}/deposit/options`,
    SWISH_DIRECT_URL: `${PAYMENT_URL}/deposit/swishdirect`,
    TRUSTLY_URL: `${PAYMENT_URL}/deposit/trustly`,
} as const;

export const CardStatuses = {
    VALID: "VALID",
    EXPIRING: "EXPIRING",
    EXPIRED: "EXPIRED",
} as const;

export const DepositStatuses = {
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
    ERROR: "ERROR",
    CANCELLED: "CANCELLED",
    DELAYED: "DELAYED",
    DECLINED: "DECLINED",
    BUDGET_EXCEEDED: "BUDGET_EXCEEDED",
    NEED_TO_SET_BUDGET: "NEED_TO_SET_BUDGET",
    INVALID_CARD: "INVALID_CARD",
    PAYEX_CHECKOUT: "PAYEX_CHECKOUT",
    PAYEX_DENIED: "DENIED",
    PAYEX_TIMEOUT: "PAYEX_TIMEOUT",
    SWISH_DIRECT_RESET: "SWISH_DIRECT_RESET",
    SWISH_DIRECT_INITIATED: "SWISH_DIRECT_INITIATED",
    SWISH_DIRECT_PENDING: "SWISH_DIRECT_PENDING",
} as const;

export const DepositStorageKeys = {
    depositModalHeader: "depositModalHeader",
    isInDepositFlow: "isInDepositFlow",
    isDepositFlowOnPage: "isDepositFlowOnPage",
    depositWithTrustlyStarted: "depositWithTrustlyStarted",
    depositOrderId: "depositOrderId",
    depositSelectedOption: "depositSelectedOption",
    userDepositPreferences: "userDepositPreferences",
} as const;

export const DepositMethods = {
    newCard: "newCard",
    existingCard: "existingCard",
    swish: "swish",
    trustly: "trustly",
} as const;
